export const information = {
  endpoint: "https://api-freecode.asiatopteam.org",
  // endpoint: "http://localhost:5035",
  f8bet: ["code-int.khuyenmaif8bet.com", "code.f8bet.la", "f8116.com", "localhost"],
  hi88: ["code.khuyenmaihi88.com", "vip.hi88.love", "code.hi88.tv", "code-live.hi88.tv"],
  jun88: ["code.jun88.fan", "code.jun88bet.us", "freecode.jun88.fan"],

  // điều hướng client-live -> hi88
  live_page: {
    f8bet: [],
    hi88: ["code-live.hi88.tv"],
    jun88: [],
  },
  // điều hướng client-free -> jun88
  free_page: {
    f8bet: ["code-int.khuyenmaif8bet.com"],
    hi88: ["code.hi88.tv"],
    jun88: ["freecode.jun88.fan", "code.jun88bet.us"],
  },
  // điều hướng client -> all
  phone_page: {
    f8bet: ["code.f8bet.la"],
    hi88: ["vip.hi88.love"],
    jun88: ["code.jun88.fan"],
  },

  cskh_url: {
    f8bet: "https://f8bet0.com/",
    hi88: "https://www.hi88.vin/",
    jun88: "https://www.jun88v1.com/",
  },

  secret_key_encrypt: "haudai@att$$2023",
};
// vip.hi88.love   // cskh sử dụng -> freecode -> không check
// code-int.khuyenmaif8bet.com  // cskh sử dụng -> freecode -> không check
// code.jun88bet.us >> JunCMD  // cskh sử dụng -> freecode -> không check

// code.jun88.fan >> JunCMD -> phone
// freecode.jun88.fan  >> JunCMD - freecode
// code.hi88.tv >> phone
// code.f8bet.la >> phone
// code-live.hi88.tv >> codelive -> live

// code.hi88.vin >> Đại lý
