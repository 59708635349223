import CryptoJS from "crypto-js";
import { information } from "../const/info.const";

export const encrypt = (text) => {
  let cipherText = CryptoJS.AES.encrypt(text, information.secret_key_encrypt).toString();
  return cipherText;
};

export const decrypt = (cipherText) => {
  let bytes = CryptoJS.AES.decrypt(cipherText, information.secret_key_encrypt);
  let originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
};
