import { information } from "../const/info.const";
import { addPointClient, getCodeClient } from "../services/api.service";
import Swal from "sweetalert2";

export const getCode = async (codeID) => {
  let site;
  console.log(window.location.hostname);
  if (information.f8bet.includes(window.location.hostname)) {
    site = "f8bet";
  } else if (information.hi88.includes(window.location.hostname)) {
    site = "hi88";
  } else if (information.jun88.includes(window.location.hostname)) {
    site = "jun88";
  }

  let codeResult = await getCodeClient(codeID, site);
  document.getElementById("submit-btn").setAttribute("click-id", "false");
  if (codeResult.status_code === 403) {
    let getYear = new Date(codeResult.detail.time).getFullYear();
    let getMonth = (new Date(codeResult.detail.time).getMonth() + 1).toString().padStart(2, "0");
    let getDate = new Date(codeResult.detail.time).getDate().toString().padStart(2, "0");
    let getHour = new Date(codeResult.detail.time).getHours().toString().padStart(2, "0");
    let getMinutes = new Date(codeResult.detail.time).getMinutes().toString().padStart(2, "0");
    let getSecond = new Date(codeResult.detail.time).getSeconds().toString().padStart(2, "0");
    let time = getDate + " tháng " + getMonth + ", " + getYear + " - " + getHour + ":" + getMinutes + ":" + getSecond;

    Swal.fire({
      icon: "error",
      title: codeResult.title_mess,
      html:
        "<div class='modal-text-wrapper'>" +
        "<div class='modal-text-cont'>" +
        "<p class='modal-text'>Mã khuyến mãi: &nbsp;</p>" +
        "<p style='font-weight: 600'>" +
        codeResult.detail.promo_code +
        "</p>" +
        "</div>" +
        "<div class='modal-text-cont'>" +
        "<p class='modal-text'>Điểm thưởng: &nbsp;</p>" +
        "<p style='font-weight: bold; color:#ec164c'>" +
        codeResult.detail.point +
        "&nbsp; điểm</p>" +
        "</div>" +
        "<div class='modal-text-cont'>" +
        "<p class='modal-text'>" +
        codeResult.detail.mess +
        " : &nbsp;</p>" +
        "<p style='font-weight: 600'>" +
        time +
        "</p>" +
        "</div>" +
        "</div>",
      footer: '<a href="' + information.cskh_url[site] + '" target="_blank">Chăm sóc khách hàng 24/7</a>',
    }).then(() => {
      window.location.reload();
    });
  } else {
    if (codeResult.valid === false) {
      Swal.fire({
        icon: "warning",
        title: codeResult.title_mess,
        text: codeResult.text_mess,
        footer: '<a href="' + information.cskh_url[site] + '" target="_blank">Chăm sóc khách hàng 24/7.</a>',
      }).then(() => {
        window.location.reload();
      });
    } else if (codeResult.valid === true) {
      Swal.fire({
        icon: "success",
        title: codeResult.title_mess,
        html:
          "<div class='modal-text-wrapper'>" +
          "<div class='modal-text-cont'>" +
          "<p class='modal-text'>Mã khuyến mãi: &nbsp;</p>" +
          "<p style='font-weight: 600'>" +
          codeResult.detail.promo_code +
          "</p>" +
          "</div>" +
          "<div class='modal-text-cont'>" +
          "<p class='modal-text'>Điểm thưởng: &nbsp;</p>" +
          "<p style='font-weight: bold; color:#ec164c'>" +
          codeResult.detail.point +
          "&nbsp; điểm</p>" +
          "</div>" +
          "<div class='modal-text-cont'>" +
          "<p class='modal-text'>" +
          codeResult.detail.mess +
          " : &nbsp;</p>" +
          "<p style='font-weight: 600'>" +
          codeResult.detail.time +
          "</p>" +
          "</div>" +
          "</div>",
        footer: '<a href="' + information.cskh_url[site] + '" target="_blank">Chăm sóc khách hàng 24/7</a>',
        input: "text",
        inputLabel: "Vui lòng nhập tên tài khoản để nhận thưởng",
        inputPlaceholder: "Tên tài khoản",
        showCancelButton: true,
        confirmButtonText: "Xác nhận",
        cancelButtonText: "Hủy",
        allowOutsideClick: false,
        inputValidator: async (playerID) => {
          if (!playerID) {
            return "Vui lòng nhập tên tài khoản!";
          } else {
            Swal.fire({
              text: "Đang xử lý",
              allowOutsideClick: false,
              width: 300,
              didOpen: () => {
                Swal.showLoading();
              },
            });

            let addPoint = await addPointClient(playerID, (codeResult?.detail?.phone ?? "") + codeResult?.detail?.promo_code, site);
            if (addPoint.status_code === 502) {
              Swal.fire({
                icon: "warning",
                title: addPoint.title_mess,
                text: addPoint.text_mess,
                footer: '<a href="' + information.cskh_url[site] + '" target="_blank">Chăm sóc khách hàng 24/7.</a>',
              }).then(() => {
                window.location.reload();
              });
            } else {
              if (addPoint.valid === false) {
                Swal.fire({
                  icon: "error",
                  title: addPoint.title_mess,
                  text: addPoint.text_mess,
                  footer: '<a href="' + information.cskh_url[site] + '" target="_blank">Chăm sóc khách hàng 24/7.</a>',
                }).then(() => {
                  window.location.reload();
                });
              } else if (addPoint.valid === true) {
                console.log("Done");
                document.getElementsByClassName("congrat-id")[0].innerHTML = addPoint.player_id;
                document.getElementsByClassName("congrat-score")[0].innerHTML = addPoint.point;
                document.getElementsByClassName("congrat-wrapper")[0].classList.add("congrat-margin");

                const Toast = Swal.mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
                Toast.fire({
                  icon: "success",
                  title: "Cộng điểm thành công",
                });
              }
            }
          }
        },
      });
    }
  }
};
