import Swal from "sweetalert2";
import { getCode } from "../controllers/api.controller";

const captchaGenerator = (getCaptcha, getCaptchaInput, codeID) => {
  let capValue = makeid();
  let captcha = document.getElementById(getCaptcha);
  let captchaInput = document.getElementById(getCaptchaInput);

  captcha.innerHTML = capValue;

  captchaInput.addEventListener("input", async () => {
    if (captchaInput.value.length > 3) {
      if (captchaInput.value === capValue) {
        captchaInput.disabled = true;
        captchaInput.classList.remove("background-input-select");

        Swal.fire({
          text: "Đang kiểm tra ...",
          allowOutsideClick: false,
          width: 300,
          didOpen: () => {
            Swal.showLoading();
          },
        });
        getCode(codeID);
      }
    }
  });
};

export default captchaGenerator;

function makeid() {
  var text = "";
  var possible = "0123456789abcdefghijklmnopqrstuvwxyz";

  for (var i = 0; i < 4; i++) text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
}
